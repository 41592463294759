import { render, staticRenderFns } from "./sendCardsTable.vue?vue&type=template&id=261d2ab1&scoped=true&"
import script from "./sendCardsTable.vue?vue&type=script&lang=js&"
export * from "./sendCardsTable.vue?vue&type=script&lang=js&"
import style0 from "./sendCardsTable.vue?vue&type=style&index=0&id=261d2ab1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261d2ab1",
  null
  
)

export default component.exports